.location-detail-carousel .carousel-slider {
  height: 100%;
}

.location-detail-carousel .carousel-slider .slider-wrapper {
  height: 100%;
}

.location-detail-carousel .carousel-slider .slider-wrapper .slider {
  height: 100%;
}

.location-detail-carousel .control-dots {
  margin-bottom: 4rem;
}