

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'HelveticaNeueW02';
}

.cursive-font {
    font-family: 'Caveat', cursive;
}

.marker {
    background-image: url('https://www.ockert-cnc.de/wp-content/uploads/2016/12/map-marker-icon.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }